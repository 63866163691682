import type { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';

import TagsPage from './components/TagsPage';

export default (
  <Route path="tags">
    <Route Component={TagsPage}>
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "tags" */ './components/TagsListSection'
          ).then((p) => p.default)
        }
        query={graphql`
          query Tags_TagsListSectionQuery(
            $tenantSlug: String!
            $count: Int!
            $cursor: String
            $sort: [TagSorting!]
          ) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...TagsListSection_tenant
            }
          }
        `}
        prepareVariables={applyFunctions<[Obj, Match]>(
          getInfiniteListVariables,
          (vars, match) => {
            const newVars = getSortVariables(vars, match);
            newVars.sort = newVars.sort ?? ['CREATED_AT_DESC'];
            return newVars;
          },
        )}
      />
    </Route>
    <Route
      path="-/new"
      getComponent={() =>
        import(/* webpackChunkName: "tags" */ './components/NewTagPage').then(
          (p) => p.default,
        )
      }
      query={graphql`
        query Tags_NewTagPageQuery($tenantSlug: String!) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NewTagPage_tenant
          }
        }
      `}
    />
    <Route path=":tagHandle">
      <Route
        getComponent={() =>
          import(/* webpackChunkName: "tags" */ './components/TagPage').then(
            (p) => p.default,
          )
        }
        query={graphql`
          query Tags_TagPageQuery($tagHandle: String!) {
            tag(handle: $tagHandle) {
              ...TagPage_tag
            }
          }
        `}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagSummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagSummarySectionQuery($tagHandle: String!) {
              tag(handle: $tagHandle) {
                ...TagSummarySection_tag
              }
            }
          `}
        />
        <Route
          path="projects"
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagProjectsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagProjectsSectionQuery(
              $tagHandle: String!
              $count: Int!
              $cursor: String
            ) {
              tag(handle: $tagHandle) {
                ...TagProjectsSection_tag
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
        <Route
          path="specimens"
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagSamplesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagSamplesSectionQuery(
              $tagHandle: String!
              $count: Int!
              $cursor: String
              $sort: [SampleSorting!]
            ) {
              tag(handle: $tagHandle) {
                ...TagSamplesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="runs"
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagRunsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagRunsSectionQuery(
              $tagHandle: String!
              $count: Int!
              $cursor: String
              $sort: [RunSorting!]
            ) {
              tag(handle: $tagHandle) {
                ...TagRunsSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="analyses"
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagAnalysesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagAnalysesSectionQuery(
              $tagHandle: String!
              $count: Int!
              $cursor: String
              $sort: [AnalysisSorting!]
            ) {
              tag(handle: $tagHandle) {
                ...TagAnalysesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="libraries"
          getComponent={() =>
            import(
              /* webpackChunkName: "tags" */ './components/TagLibrariesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Tags_TagLibrariesSectionQuery(
              $tagHandle: String!
              $count: Int!
              $cursor: String
              $sort: [LibrarySorting!]
            ) {
              tag(handle: $tagHandle) {
                ...TagLibrariesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "tags" */ './components/EditTagPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Tags_EditTagPageQuery(
            $tagHandle: String!
            $tenantSlug: String!
          ) {
            tag(handle: $tagHandle) {
              ...EditTagPage_tag
            }
            tenantBySlug(slug: $tenantSlug) {
              ...EditTagPage_tenant
            }
          }
        `}
      />
    </Route>
  </Route>
);
